.flex-item {
  position: relative;
  text-align: center;
  background-color: #302d24;
  height: 510px;
  width: 350px;
  margin-bottom: 15px;
}
.station-container {
  padding: 18px 15px 0;
}
.arrow-container {
  border: #82817f 4px solid;
  width:150px;
  height:150px;
  border-radius: 100px;
  margin: auto;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  -ms-transition: -ms-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
}
.svgArrow {
  fill: white;
  transform: rotate(90deg);
  margin-top: -18px;
}
.station-title {
  padding: 10px 0 5px;
  margin-top: 7px;
}
.station-title h2 {
  font-weight: 500;
  margin: 0;
  font-size: 20px;
}
.outer-arrow-container {
  margin-bottom: 10px;
}
.station-speed-gust {
  font-size: 56px;
  color: #e3e2e1;
  /* margin-top: -130px;
  margin-left: -5px; */

  position: absolute;
    left: 124px;
    top: 90px;
    text-align: center;
    width: 100px;
}
.station-gust {
  font-size: 30px;
}
.last-updated {
  position: absolute;
  top: 75px;
  left: 25px;
  color: #ddd;
}
.station-temp {
  position: absolute;
  top: 75px;
  right: 25px;
  color: #ccc;
}
.error-or-no-data.outer-arrow-container {
  height: 150px;
  font-size:20px;
  line-height:30px;
  padding-top:30px;
}
.inner-item {
  font-size: 16px;
  margin-top: 50px;
}
.outer-bg-image {
  cursor: pointer;
  position: relative;
  height: 195px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: 80% 10%;
}
.inner-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: 80% 10%;
  transition: background-size .6s ease-in-out;
}

.inner-bg-image.top {
  cursor: pointer;
}
.inner-bg-image.top:hover {
  background-size: 370px;
}
.history-small-container {
  cursor: pointer;
}

.history-small-row {
  display: flex;
  justify-content: space-between;
}
.history-small-row.time-range {
  padding-top: 1px;
}
.history-small-item {
  flex-direction: row;
  text-align: center;
}
.deemphisized-text {
  font-size: 13px;
  color: #bfbfbf;
}
.inner-item-history {
  height: 82px; /* stop it from jumping on refresh */
  padding: 10px 16px 0;
  margin-bottom: 8px;
}
.inner-item-history:hover, .station-title:hover {
  background-color: #1f1d17;
}
