.alert-banner-container {
  margin-top: -4px;
  width: 100%;
}

.alert-banner-outer {
  width: 100%;
  min-width: 250px;
  text-align: center;
  padding-top: 5px;
}
.alert-banner-outer.none {
  display: none;
}
.alert-banner-outer.grey .alert-banner-inner {
  color: #bbb8b8;
}
.alert-banner-inner {
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin: auto;
  width: 50%;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px 15px 15px;
  max-width: 700px;
  /* position: relative; */
}
.alert-banner-inner a {
  text-decoration: underline;
}

.red,
.danger {
  background-color: #d9534f;
}
.yellow,
.warning {
  background-color: #a56408;
}
.green,
.success {
  background-color: #5cb85c;
}
.blue,
.primary {
  background-color: #5bc0de;
}
.black,
.dark {
  background-color: #1f1d17;
}
.grey {
  background-color: #302d24;
}

.close-banner {
  padding-left: 10px;
  line-height: 12px;
  color: #fff;
}
.close-banner:hover {
  color: #aaa
}

@media only screen and (max-width : 800px) {
  .alert-banner-inner {
    width: 95%;
  }
}
