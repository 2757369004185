body {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin: 0px;
  background: #1f1d17;
  /* padding: 0; */
}
a, a:visited {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: #efefef
}
* {
  box-sizing: border-box;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button, select {
  text-transform: none;
}
button, input {
  overflow: visible;
}
button, input, select, textarea, optgroup {
  font-weight: 200;
  font: inherit;
}
.inner-content {
  position: relative;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 20px 0;
}
.menu-button {
  position: absolute;
  right: 13px;
  top: 12px;
  font-size: 40px;
  cursor: pointer;
  z-index: 2;
  opacity: .7;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, .7);
  width: 65px;
  height: 60px;
}
.menu-button:hover {
  background-color: rgba(0, 0, 0, 1);
  opacity: 1;
}
.site-select-container {
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 15px;
  opacity: 0.6;
  background-color: #000;
  /* background-color: rgba(0, 0, 0, .5); */
  padding: 15px;
  border-radius: 5px;
}
.site-select-container:hover {
  opacity: 1;
}
.change-location {
  width: 220px;
  text-align: right;
}
.change-location select{
  width: 100%;
  margin-top: 10px;
  padding: 3px;
  background-color: #ccc;
  font-weight: 400;
  border-radius: 7px;

}
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.version {
  opacity: .4;
  float: right;
  padding: 40px 40px 0 0;
}

@media only screen and (max-width : 479px) {
  .menu-button {
    opacity: unset;
    background-color: #302d24;
  }
  .menu-button:hover {
    background-color: rgba(0, 0, 0, 1);
  }
  .site-select-container {
    opacity: unset;
    background-color: #302d24;
  }
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e1e1e1;
  box-shadow: inset 0 0 8px black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
