/* The Modal styles */
.modal {
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px 20px 25px 20px;
  border: 2px solid #555;
  border-radius: 5px;
  width: 90%;
  background-color: #302d24;
  max-height: 80%;
  overflow: auto;
  resize: both;
  overflow-y: hidden;
}
.modal-content.iFrame {
  height: 70%;
}
.modal-content iframe {
  background-color: white;
}
.chart-content-inner {
  padding: 30px 5px 15px;
}
.highcharts-range-selector-group {
  display:none;
}

.modal-img-link>img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  max-height: 90%;
}
.modal-content-inner {
  width: 96%;
  margin: auto;
  /* background-image: url("../images/loaderDuck.gif"); */
  background-image: url('../images/loaderDuck.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px;
  min-height: 300px;
  height: 94%;
}
.modal-content-loaded .modal-content-inner {
  background-image: unset;
}
.modal-dynamic-container iFrame {
  height: 80%;
  min-width: 250px;
  width: 100%;
  resize: both;
}

.modal-dynamic-container iFrame.larger {
  zoom: 1.3;
  -moz-transform: scale(1.3);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1.3);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1.3);
  -webkit-transform-origin: 0 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  #scaled-frame {
    zoom: 1;
  }
}

.modal-dynamic-container {
  margin: auto;
  min-width: 250px;
  height: 92%;
  overflow: auto;
}

h3.modal-title {
  margin-top: 5px;
  margin-bottom: 14px;
}
.sub-header-content {
  margin-bottom: 20px
}
.close-modal-btn {
  display:inline-block;
  margin: -20px -20px 0 0;
  padding: 0 8px;
  background-blend-mode: darken;
}
.additional-content {
  text-align: left;
  padding-top: 25px;
  line-height: 25px;
}
.additional-content ul {
    list-style-type: circle;
    margin-block-start: .3em;
    margin-block-end: .5em;
    padding-inline-start: 20px;
}
.additional-content a{
  margin-left: 20px;
}
.additional-content li a{
  margin-left: 0;
}
.image-text {
  padding-top: 7px;
}
@media only screen and (max-width : 479px) {
  .camera-message {
    font-size: 18px;
  }
  .menu-button {
    backround: unset;
  }
}
@media only screen and (min-width : 1000px) {
  .modal-content {
    width: 800px;
    /* height: 80% */
  }
  .site-select-container, .menu-button {
    position: fixed;
  }
}
